import React from 'react'
import Layout from '../../components/layout'
import Img from 'gatsby-image/withIEPolyfill'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
export const query = graphql`
  query CertificationQuery {
    certImages: allFile(filter: { relativeDirectory: { eq: "certification" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    graduateImages: allFile(filter: { relativeDirectory: { eq: "certification/graduates" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

class Certification extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const first = this.props.data.certImages.edges.filter(
            edge => edge.node.childImageSharp.fluid.src.indexOf('a.jpg') !== -1
        )[0].node.childImageSharp.fluid
        const map = this.props.data.certImages.edges.filter(
            edge => edge.node.childImageSharp.fluid.src.indexOf('b.png') !== -1
        )[0].node.childImageSharp.fluid
        const one = this.props.data.certImages.edges.filter(
            edge => edge.node.childImageSharp.fluid.src.indexOf('c.jpg') !== -1
        )[0].node.childImageSharp.fluid
        const two = this.props.data.certImages.edges.filter(
            edge => edge.node.childImageSharp.fluid.src.indexOf('d.png') !== -1
        )[0].node.childImageSharp.fluid
        const three = this.props.data.certImages.edges.filter(
            edge => edge.node.childImageSharp.fluid.src.indexOf('e.png') !== -1
        )[0].node.childImageSharp.fluid

        let graduateList = [];
        console.log(this.props.data.graduateImages.edges)
        this.props.data.graduateImages.edges.forEach(graduate => {
            graduateList.push(
                (
                    <div className="flex w-1/2 p-12">
                        <div className="w-full rounded-full">
                            <Img fluid={graduate.node.childImageSharp.fluid} />
                        </div>
                    </div>
                )
            )
        });
        return (
            <Layout>
                <section className="section-padding-large biq-bg-prim biq-text-white">
                    <div className="container">
                        <h1>Certifications</h1>
                        <div className="biq-text-xl text-center mt-10">Deepen your practice or teach others to breathe biomechanics and psychology of breathing</div>
                    </div>
                </section>
                <section className="section-padding">
                    <div className="container">
                        <div className="flex flex-row flex-wrap w-full">
                            <div className="flex justify-center place-items-center w-full lg:w-1/2">
                                <div className="w-full p-8">
                                    <Img fluid={one} />
                                </div>
                            </div>
                            <div className="flex flex-col w-full lg:w-1/2">
                                <h2 className="font-bold">Breathe Certification</h2>
                                <div className="flex mt-8">
                                    $1,500.00
                                </div>
                                <ul className="flex flex-col mt-8 font-semibold">
                                    <li>4 Weekly Video Tutorials</li>
                                    <li>5 1-on-1 Remote Live Sessions</li>
                                    <li>1 hour FAQ call with Dr. Belisa</li>
                                </ul>
                                <div className="flex mt-8">
                                    Schedule:
                                </div>
                                <div className="flex flex-col mt-8 px-4">
                                    <div className="flex flex-row">
                                        <div className="flex w-1/3 font-semibold">
                                            Weeks 1 - 4
                                </div>
                                        <div className="flex w-1/2">
                                            Videos
                                </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="flex w-1/3 font-semibold">
                                            Weeks 5-9
                                </div>
                                        <div className="flex w-1/2">
                                            1-hour Live Private Remote Class w/ Instructor
                                </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="flex w-1/3 font-semibold">
                                            Weeks 10
                                </div>
                                        <div className="flex w-1/2">
                                            1-hour Live FAQ Call w/ Dr. Belisa
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding biq-bg-gray">
                    <div className="container">
                        <div className="container">
                            <div className="flex flex-row lg:flex-row-reverse flex-wrap w-full">
                                <div className="flex justify-center place-items-center w-full lg:w-1/2">
                                    <div className="w-full p-8">
                                        <Img fluid={two} />
                                    </div>
                                </div>
                                <div className="flex flex-col w-full lg:w-1/2">
                                    <h2 className="font-bold">Parents and Teacher Certification</h2>
                                    <div className="flex mt-8">
                                        $1,500.00
                                    </div>
                                    <div className="flex text-lg mt-8">This course starts with the foundational concepts and exercises of the BREATHE teachings. </div>
                                    <div className="flex text-lg mt-8">Parents/Teachers will then learn how: </div>
                                    <ol className="flex flex-col mt-8 px-4">
                                        <li>To do a breathing screening (the BIQ) for their team, classroom or family.</li>
                                        <li>Teach correctives and exercises with proper cueing for the age range they are working with.</li>
                                        <li>Teach breathing for stress reduction, test taking, and performance jitters.</li>
                                    </ol>
                                    <div className="flex text-lg mt-8">Other topics to be covered are</div>
                                    <ul className="flex flex-col mt-8 px-4">
                                        <li>Breathing exercises for asthma </li>
                                        <li>Breathing exercises for endurance in sports </li>
                                        <li>Breathing exercises for a strong respiratory system</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding">
                    <div className="container">
                        <div className="flex flex-row flex-wrap w-full">
                            <div className="flex justify-center place-items-center w-full lg:w-1/2">
                                <div className="w-full p-8">

                                    <Img fluid={three} />
                                </div>
                            </div>
                            <div className="flex flex-col w-full lg:w-1/2">
                                <h2 className="font-bold">Warrior Certification</h2>
                                <div className="flex mt-8">
                                    $2,250
                        </div>
                                <div className="flex text-lg mt-8">Two payments of $1,125 ( or $2,250)</div>
                                <div className="flex mt-8 font-semibold">
                                    Part 1:
                        </div>
                                <ul className="flex flex-col mt-8 px-4">
                                    <li>4 video classes</li>
                                    <li>4 one-on-one classes (Zoom or Skype) with senior teacher</li>
                                </ul>
                                <div className="flex mt-8 font-semibold">
                                    Part 2:
                        </div>
                                <ul className="flex flex-col mt-8 px-4">
                                    <li>4 video classes</li>
                                    <li>2 live online private classes with senior instructors in the gym</li>
                                    <li>1 session with Dr. Belisa</li>
                                </ul>
                                <div className="flex text-lg mt-8">
                                    You choose your speed. Once a week (4 month class) OR twice a week (2 month class).
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding biq-bg-gray">
                    <div className="container">
                        <div className="flex flex-row flex-wrap w-full">
                            <div className="flex justify-center place-items-center w-full md:w-1/2 lg:px-8">
                                <div class="w-10/12">
                                    <Img fluid={first} />
                                </div>
                            </div>
                            <div className="flex justify-center place-items-center w-full md:w-1/2 lg:px-8">
                                <ul className="flex flex-col mt-8 md:px-4 text-xl md:text-3xl">
                                    <li>FLEXIBLE SCHEDULING</li>
                                    <li>INTENSIVE, PRACTICAL, SCIENCE BASED</li>
                                    <li>REMOTE OR LIVE OPTION</li>
                                    <li>DIRECTION AND FEEDBACK</li>
                                    <li>THOROUGH RESOURCES FOR FURTHER STUDY</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* INFO SECTION */}
                <section className="section-padding">
                    <div className="container">
                        <div className="flex flex-col">
                            <div className="flex">
                                <div className="w-full">
                                    <Img fluid={map} />
                                </div>
                            </div>
                            <div className="flex flex-row flex-wrap justify-between">
                                <div className="flex flex-col w-full md:w-5/12 mt-4">
                                    <div className="flex biq-text-xl">Be Inspirational</div>
                                    <div className="flex">If you are a physical therapist, life coach, personal trainer, yoga instructor, or coach, you want to be outstanding in your field and on the cutting edge of health science. You want to become an inspiring teacher, to differentiate yourself from the rest, and help people discover newfound health, strength, vitality, creativity, inner peace, endurance, and greater well-being in their everyday lives.</div>
                                </div>
                                <div className="flex flex-col w-full md:w-5/12 mt-4">
                                    <div className="flex biq-text-xl">Be Life Changing</div>
                                    <div className="flex">Are you trying to be healthier, are you seeking to deepen your meditation practice? Are you contemplating the idea of teaching? If you’re looking to add more passion, meaning, and purpose to your life, and to help change the lives of others, the BREATHE™ Certification Training Program is for you.</div>
                                </div>
                            </div>
                            <div className="mt-8">
                                <span className="font-semibold">The BREATHE™ Certification Training Program</span> is a complete course in the theory, science, and practice of breathing and breathing meditation. It is designed to prepare individuals to deliver professional breathwork and active meditation instruction to their clients, patients, athletes, and to those healing from medical and emotional issues. The BREATHE™ Certification Training Program will give you the high degree of specialized training you need to teach numerous breathing techniques and a breathing-centered meditation.
                            </div>
                            <div className="text-xl md:text-3xl text-center font-semibold mt-10">THE BREATHE™ CERTIFICATION TRAINING PROGRAM IS PERFECT FOR:</div>
                            <ul className="flex flex-wrap mt-8 px-4 biq-text-lg">
                                <li className="w-full md:w-1/3">Acupuncturists</li>
                                <li className="w-full md:w-1/3">Addiction Professionals</li>
                                <li className="w-full md:w-1/3">Chiropractors</li>
                                <li className="w-full md:w-1/3">College Professors</li>
                                <li className="w-full md:w-1/3">Educators</li>
                                <li className="w-full md:w-1/3">Elementary & High School Teachers</li>
                                <li className="w-full md:w-1/3">Healers</li>
                                <li className="w-full md:w-1/3">Health & Wellness Coaches</li>
                                <li className="w-full md:w-1/3">Massage Therapists</li>
                                <li className="w-full md:w-1/3">Nurses</li>
                                <li className="w-full md:w-1/3">Personal Trainers</li>
                                <li className="w-full md:w-1/3">Physical Therapists</li>
                                <li className="w-full md:w-1/3">Physical Trainers</li>
                                <li className="w-full md:w-1/3">Physicians</li>
                                <li className="w-full md:w-1/3">Pilates Instructors</li>
                                <li className="w-full md:w-1/3">Psychiatrists</li>
                                <li className="w-full md:w-1/3">Psychologists</li>
                                <li className="w-full md:w-1/3">Social Workers</li>
                                <li className="w-full md:w-1/3">Yoga Teachers</li>

                            </ul>
                        </div>
                    </div>
                </section>
                {/* What you will receive */}
                <section className="section-padding biq-bg-gray">
                    <div className="container">
                        <h2 className="font-semibold">What you will receive</h2>
                        <div className="flex flex-row flex-wrap w-full">
                            <ul className="flex flex-col flex-wrap px-4 biq-text-lg w-full md:w-1/2 mt-4">
                                <li className="w-full">Access to a web page exclusively for certified teachers private teacher webpage with links to reference materials, including videos and audios</li>
                                <li className="w-full">Feedback and evaluation on quizzes that determine your comprehension</li>
                                <li className="w-full">Opportunities for paid referrals</li>
                                <li className="w-full">Discounts on seminars, retreats, and products</li>
                                <li className="w-full">Invitation to attend future  Certification Programs to support others in training</li>
                                <li className="w-full">The opportunity to apply to the International Global Breathwork Group</li>
                                <li className="w-full">Continuing Education Units</li>

                            </ul>
                            <div className="flex flex-col border-solid border-2 border-black p-2 w-full md:w-1/2 mt-4">
                                <div className="font-semibold biq-text-lg text-center py-4">
                                    Continuing Education Units
                                </div>
                                <div className="text-xl text-center py-4">
                                    CEUs are available for the following associations:
                                </div>
                                <div className="font-semibold biq-text-lg text-center">
                                    ISSA
                                </div>
                                <div className="font-semibold biq-text-lg text-center">
                                    ACE
                                </div>
                                <div className="font-semibold biq-text-lg text-center">
                                    NASM
                                </div>
                                <div className="font-semibold biq-text-lg text-center">
                                    AFAA
                                </div>
                                <div className="font-semibold biq-text-lg text-center">
                                    Yoga Alliance
                                </div>
                                <div className="text-xl text-center py-4">
                                    Associations pending for CEUs
                                </div>
                                <div className="font-semibold biq-text-lg text-center">
                                    NSCA
                                </div>
                                <div className="font-semibold biq-text-lg text-center">
                                    ACSM
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding">
                    <div className="container">
                        <h2>Want to know what people are saying about the breathing class?</h2>
                        <div className="flex flex-col">
                            <div className="flex mt-4">
                                <iframe src="https://player.vimeo.com/video/182899496?title=0&byline=0&portrait=0" width="100%" height="500" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                            </div>
                            <div className="flex flex-row flex-wrap">
                                <div className="flex w-full md:w-1/2 md:px-2 mt-4">
                                    <iframe src="https://www.youtube.com/embed/Hvxz7KJkLdw?rel=0" width="100%" height="400" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                </div>
                                <div className="flex w-full md:w-1/2 md:px-2 mt-4">
                                    <iframe src="https://www.youtube.com/embed/fMCY9ewPqrE?rel=0" width="100%" height="400" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Graduates */}
                <section className="section-padding biq-bg-gray">
                    <div className="container">
                        <h2>Certification Training Graduates</h2>
                        <div className="biq-text-xl text-center font-semibold">Where are they now?</div>
                        <div className="flex flex-row flex-wrap">
                            {graduateList}
                        </div>
                    </div>
                </section>
                <section className="section-padding">
                    <div className="container">
                        <h2>Frequently Asked Questions</h2>
                        <Accordion className="mt-8">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        HOW DO I KNOW IF THIS IS THE CLASS FOR ME?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        If you have read BREATHE and liked it, it’s for you.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        WITH THE “REMOTE” CHOICE, WHO IS THE INSTRUCTOR?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        A senior teacher for five sessions, the final hour long l private phone sessions with Dr. Belisa is for you to ask and cover all your advanced and personal questions.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        WEEK THREE OF VIDEO IS A WEEK WHEN I AM HAVING SURGERY, I DOUBT I’LL BE ABLE TO DO IT THAT WEEK, CAN I DOUBLE UP THE WEEK AFTER?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Yes.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I’M GOING TO GO FOR OPTION C BECAUSE I WANT MORE ONE-ON-ONE ATTENTION AND BE ABLE TO ASK QUESTIONS RIGHT AWAY. WHAT DAY CAN I START?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        You set your own start date. Just remember you need to have completed all sessions within 12 weeks.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        WHAT HAPPENS AFTER GRADUATION?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Once completed you will have access to the private certification forum and  be allowed to apply to Masterclass, held once a year in New York.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        WILL I BE REFERRED CLIENTS FROM THE BREATHING CLASS?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Depending on your level of expertise and commitment, you may be referred clients. This depends on your performance and area of specialization. Please make sure you let your instructor know this interests you when you start.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        I DON’T KNOW IF I WANT TO TEACH, I JUST LIKE BREATHING, IS THIS CLASS RIGHT FOR ME?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Even if you just want to deepen your knowledge and practice, yes, this is a good class for you.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        DO YOU HAVE PAYMENT PLANS?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Yes, <a href="mailto:crystal@thebreathingclass.com" className="biq-text-sec">email</a> us and tell us what you can do. We’ll do the best we can to accommodate you.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        CAN I SPEAK TO AN INSTRUCTOR BEFORE I DECIDE?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Yes, <a href="mailto:crystal@thebreathingclass.com" className="biq-text-sec">email</a> us and we’ll set that up.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        DO I HAVE TO HAVE A BACKGROUND IN ANATOMY, PSYCHOLOGY OR HEALTH TO DO THIS CLASS?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        No, you just have to have an interest in it!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        MY SPOUSE, FRIEND, COWORKER WANTS TO TAKE THIS WITH ME. HOW DOES THIS WORK?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        There is different pricing for several people taking the class, or group pricing. Please <a href="mailto:crystal@thebreathingclass.com" className="biq-text-sec">email</a> us for more information.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        CAN I TAKE THIS CLASS AND SHARE IT WITH MY COLLEAGUES?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        This course is affordably priced and we offer group discounts and payment plans. While books and articles that are recommended should be shared enthusiastically, sharing lesson plans and teaching information is not nice.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        WHERE ARE YOUR TEACHERS NOW?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        All over the globe! For example some have added their BREATHE certification to an existing job such as a massage therapist or fitness trainers and some have become full-time breathing teachers.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        CAN I SPEAK TO ONE?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Yes. <a href="mailto:crystal@thebreathingclass.com" className="biq-text-sec">email</a> us!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        WHERE WILL THE WORKSHOP BE LOCATED?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        At my studio on 45th street and 5th Avenue in New York City.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        WILL IT BE A LARGE CLASS?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        No, it is meant to be a small intensive class
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        WILL THE CONTENT OF THE NEW BOOK BREATHING FOR WARRIORS BE COVERED IN THIS CLASS?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        This is a fundamentals class; before specializing you need to be very comfortable and well versed in this content. From here you start getting experience can learning about different demographics and can take the advanced BFW certification.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        DO YOU RECOMMEND WE TAKE OTHER CLASSES WITH OTHER TEACHERS AFTER THIS ONE.
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Absolutely. Dr. Belisa often recommends you continue with other teachers who have experience in areas that you’ve realized interest you. One of our missions is to be a collaborative community of breath workers.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </section>
            </Layout>)
    }
}

export default Certification
